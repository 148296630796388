import React, { createContext, useContext } from "react"

export const hreflangTags = [
  { lang: "x-default", href: "https://www.vinomofo.com" },
  { lang: "en-NZ", href: "https://www.vinomofo.co.nz" },
  { lang: "en-SG", href: "https://www.vinomofo.com.sg" },
]

export const AllMarkets = {
  AU: {
    name: "Australia",
    code: "AU",
    lang: "en-AU",
    href: "https://www.vinomofo.com",
    canonical_url: "https://www.vinomofo.com",
    telephone: "1300 846 666",
    twitter: "@vinomofo",
    facebook: "@vinomofo",
    instagram: "@vinomofo",
    youtube: "@vinomofo",
    linkedIn: "@vinomofo",
    slugPrefix: "/au",
  },
  NZ: {
    name: "New Zealand",
    code: "NZ",
    lang: "en-NZ",
    href: "https://www.vinomofo.co.nz",
    canonical_url: "https://www.vinomofo.com",
    telephone: "0800 884 666",
    twitter: "@vinomofo_nz",
    facebook: "@vinomofo",
    instagram: "@vinomofo",
    youtube: "@vinomofo",
    linkedIn: "@vinomofo",
    slugPrefix: "/nz",
  },
  SG: {
    name: "Singapore",
    code: "SG",
    lang: "en-SG",
    href: "https://www.vinomofo.com.sg",
    canonical_url: "https://www.vinomofo.com",
    telephone: "800 1206 722",
    twitter: "@vinomofo_sg",
    facebook: "@vinomofo",
    instagram: "@vinomofo",
    youtube: "@vinomofo",
    linkedIn: "@vinomofo",
    slugPrefix: "/sg",
  },
}

const currentMarket = AllMarkets[process.env.GATSBY_SITE_MARKET]

const context = createContext(currentMarket)

export const useMarketContext = () => {
  return useContext(context)
}

export const MarketContextWrapper = ({ children }) => {
  return <context.Provider value={currentMarket}>{children}</context.Provider>
}
